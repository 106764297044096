<template>
  <div>
    <v-row v-if="isMobileWidth === 1" class="ma-4 mt-0 pt-0">
        <v-col cols="12">
            <detail-campaign></detail-campaign>
        </v-col>
    </v-row>
    <v-row v-else class="">
        <v-col cols="12">
            <detail-campaign></detail-campaign>
        </v-col>
    </v-row>
  </div>
</template>

<script>
import DetailCampaign from './components/DetailCampaign.vue'
import axios from 'axios'

export default {
  name: 'campaign',
  metaInfo: {
    title: 'Campaign Detail'
  },
  components: {
    DetailCampaign
  },
  setup() {
    return {
      isMobileWidth:0
    }
  },
  mounted(){
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })

    if(!localStorage.getItem('token')){
      this.$router.push({name:'login'});
    }
  },
  methods:{
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  }
}
</script>
