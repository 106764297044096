<template>
  <div>
    <!-- MOBILE -->
    <div v-if="isMobileWidth === 1" class="mb-5 pb-10" style="height:100%">
      <div v-if="reload" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
        <v-card
          width="47%"
          height="270px"
          class="round mb-3"
          elevation="0"
          outlined
          v-for="n in 6"
          :key="n+'A'"
        >
        <v-skeleton-loader
          type="card"
        ></v-skeleton-loader>
        </v-card>
      </div>
      <div v-else>
        <div v-if="isLoading" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
          <v-card
            width="47%"
            height="270px"
            class="round mb-3"
            elevation="0"
            outlined
            v-for="n in 6"
            :key="n+'A'"
          >
          <v-skeleton-loader
            type="card"
          ></v-skeleton-loader>
          </v-card>
        </div> 
        <div v-else>  
          <div class="row pl-3 pr-3 d-flex justify-space-between pt-3">
            <v-card
              width="47%"
              height="270px"
              class="round mb-3"
              elevation="0"
              outlined
              v-for="list in listInfluencers"
              :key="list.id"
            >
              <v-img
                class="pointer" @click="goToDetail(list.id)" height="150"
                :src="list.photo"
              >
                <v-app-bar
                  flat
                  color="rgba(0, 0, 0, 0)"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="list.isPriority === 1"
                    elevation="0"
                    color="white"
                    fab
                    x-small
                    class="recommended"
                    aria-label="recomended"
                  >
                    <v-icon class="primary--text">
                      {{ icons.mdiLightningBolt }}
                    </v-icon>
                  </v-btn>
                </v-app-bar>
              </v-img>

              <v-card-text class="pa-2 pb-0">
                <p v-if="list.name.length < 16" class="left-text black--text text-14 font-weight-bold mb-1">{{ list.name }}</p>
                <p v-else class="left-text black--text text-14 font-weight-bold mb-1">{{list.name.substring(0,16)+".."}}</p>
                <!-- TIKTOK -->
                <div v-if="type === 'tiktok'">
                  <div v-if="dataCampaign[0].status === 1">
                    <v-btn v-if="list.choose === 1" @click.prevent="unSelected(list.num, list.id)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="error"><i class="fas fa-times-circle pr-1"></i>Batalkan Pilih</v-btn>
                    <v-btn v-else @click.prevent="selected(list.num, list.id)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-check-circle pr-1"></i>Pilih</v-btn>
                  </div>
                  <div v-else>
                    <v-btn @click.prevent="goToDetail(list.id)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-eye pr-1"></i>Lihat Detail</v-btn>
                  </div>
                  <p v-if="list.tiktok.length < 20" class="mb-0 text-12"><i class="fab fa-tiktok pr-1"></i>{{ list.tiktok }}</p>
                  <p v-else class="mb-0 text-12"><i class="fab fa-tiktok pr-1"></i>{{list.tiktok.substring(0,20)+".."}}</p>
                  <p class="text-12 mb-0"><i class="far fa-user-circle pr-1"></i>{{list.follower_tiktok | nFormatter}} Followers</p>
                </div>
                <!-- INSTAGRAM -->
                <div v-else>
                  <div v-if="dataCampaign[0].status === 1">
                    <v-btn v-if="list.choose === 1" @click.prevent="unSelected(list.num, list.id)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="error"><i class="fas fa-times-circle pr-1"></i>Batalkan Pilih</v-btn>
                    <v-btn v-else @click.prevent="selected(list.num, list.id)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-check-circle pr-1"></i>Pilih</v-btn>
                  </div>
                  <div v-else>
                    <v-btn @click.prevent="goToDetail(list.id)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-eye pr-1"></i>Lihat Detail</v-btn>
                  </div>
                  <p v-if="list.instagram.length < 20" class="mb-0 text-12"><i class="fab fa-instagram pr-1"></i>{{ list.instagram }}</p>
                  <p v-else class="mb-0 text-12"><i class="fab fa-instagram pr-1"></i>{{list.instagram.substring(0,20)+".."}}</p>
                  <p class="text-12 mb-0"><i class="far fa-user-circle pr-1"></i>{{list.follower_ig | nFormatter}} Followers</p>
                </div>
              </v-card-text>
            </v-card>
          </div>

          <div v-if="dataCampaign[0].status === 1" class="row pa-3 mt-3 d-flex justify-center">
            <v-progress-circular
              v-if="isLoadingMore"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <v-btn v-else @click="loadMore()" outlined color="primary" rounded class="text-capitalize">Load More</v-btn>
          </div>

          <!-- MENU BAWAH -->
          <div v-if="dataCampaign[0].status === 1">
            <v-bottom-navigation
              v-if="!isLoading"
              color="white"
              fixed
              class="d-flex justify-space-between"
              horizontal
              style="bottom:-1px;box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important"
            >
              <div v-for="list in dataCampaign" :key="list.id">
                <v-btn text class="mr-0" color="primary" style="height:56px !important; color:#fff !important">
                  <span class="subtitle-2 font-weight-bold primary--text">{{ (list.level === 'A') ? 'Nano ' : 'Micro ' }} ({{ (list.level === 'A') ? selectQty : selectQty }}{{ '/'+list.qty }})</span>
                </v-btn>
              </div>
              <div class="pb-0 mb-0">
                <v-btn @click.prevent="save()"  small color="primary" block style="height:56px !important; color:#fff !important;">
                    <span class="caption font-weight-bold">Simpan</span>
                    <i class="fas fa-save mr-2"></i>
                </v-btn>
              </div>
            </v-bottom-navigation>
          </div>
          <div v-else>
            <v-bottom-navigation
              v-if="!isLoading"
              color="white"
              fixed
              class="d-flex justify-space-between"
              horizontal
              style="bottom:-1px;box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important"
            >
                <v-btn text class="mr-0" color="primary" style="height:56px !important; color:#fff !important">
                  <span class="subtitle-2 font-weight-bold primary--text">Total {{ dataCampaign[0].qty }} Influencer</span>
                </v-btn>
              <div class="pb-0 mb-0">
                <v-btn @click.prevent="goToReport()"  small color="primary" block style="height:56px !important; color:#fff !important;">
                    <span class="caption font-weight-bold">Lihat Report</span>
                    <i class="far fa-file mr-2"></i>
                </v-btn>
              </div>
            </v-bottom-navigation>
          </div>
        </div>
      </div>
    </div>

    <!-- DESKTOP -->
    <div v-else class="mb-5 pb-10" >
      <div v-if="reload" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
        <v-card
          width="100%"
          height="270px"
          class="round mb-3"
          elevation="0"
          outlined
          v-for="n in 3"
          :key="n+'A'"
        >
        <v-skeleton-loader
          type="card"
        ></v-skeleton-loader>
        </v-card>
      </div>
      <div v-else>
        <div v-if="isLoading" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
          <v-card
            width="190px"
            height="270px"
            class="round mb-3"
            elevation="0"
            outlined
            v-for="n in 6"
            :key="n+'A'"
          >
          <v-skeleton-loader
            type="card"
          ></v-skeleton-loader>
          </v-card>
        </div> 
        <div v-else>  
          <div class="row pl-3 pr-3 d-flex justify-space-between pt-3">
            <v-card
              width="190px"
              height="270px"
              class="round mb-3"
              elevation="0"
              outlined
              v-for="list in listInfluencers"
              :key="list.id"
            >
              <v-img
                class="pointer" @click="goToDetail(list.id)" height="150"
                :src="list.photo"
              >
                <v-app-bar
                  flat
                  color="rgba(0, 0, 0, 0)"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="list.isPriority === 1"
                    elevation="0"
                    color="white"
                    fab
                    x-small
                    class="recommended"
                    aria-label="recomended"
                  >
                    <v-icon class="primary--text">
                      {{ icons.mdiLightningBolt }}
                    </v-icon>
                  </v-btn>
                </v-app-bar>
              </v-img>

              <v-card-text class="pa-2 pb-0">
                <p v-if="list.name.length < 16" class="left-text black--text text-14 font-weight-bold mb-1">{{ list.name }}</p>
                <p v-else class="left-text black--text text-14 font-weight-bold mb-1">{{list.name.substring(0,16)+".."}}</p>
                <!-- TIKTOK -->
                <div v-if="type === 'tiktok'">
                  <div v-if="dataCampaign[0].status === 1">
                    <v-btn v-if="list.choose === 1" @click.prevent="unSelected(list.num, list.id)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="error"><i class="fas fa-times-circle pr-1"></i>Batalkan Pilih</v-btn>
                    <v-btn v-else @click.prevent="selected(list.num, list.id)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-check-circle pr-1"></i>Pilih</v-btn>
                  </div>
                  <div v-else>
                    <v-btn @click.prevent="goToDetail(list.id)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-eye pr-1"></i>Lihat Detail</v-btn>
                  </div>
                  <p v-if="list.tiktok.length < 20" class="mb-0 text-12"><i class="fab fa-tiktok pr-1"></i>{{ list.tiktok }}</p>
                  <p v-else class="mb-0 text-12"><i class="fab fa-tiktok pr-1"></i>{{list.tiktok.substring(0,20)+".."}}</p>
                  <p class="text-12 mb-0"><i class="far fa-user-circle pr-1"></i>{{list.follower_tiktok | nFormatter}} Followers</p>
                </div>
                <!-- INSTAGRAM -->
                <div v-else>
                  <div v-if="dataCampaign[0].status === 1">
                    <v-btn v-if="list.choose === 1" @click.prevent="unSelected(list.num, list.id)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="error"><i class="fas fa-times-circle pr-1"></i>Batalkan Pilih</v-btn>
                    <v-btn v-else @click.prevent="selected(list.num, list.id)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-check-circle pr-1"></i>Pilih</v-btn>
                  </div>
                  <div v-else>
                    <v-btn @click.prevent="goToDetail(list.id)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-eye pr-1"></i>Lihat Detail</v-btn>
                  </div>
                  <p v-if="list.instagram.length < 20" class="mb-0 text-12"><i class="fab fa-instagram pr-1"></i>{{ list.instagram }}</p>
                  <p v-else class="mb-0 text-12"><i class="fab fa-instagram pr-1"></i>{{list.instagram.substring(0,20)+".."}}</p>
                  <p class="text-12 mb-0"><i class="far fa-user-circle pr-1"></i>{{list.follower_ig | nFormatter}} Followers</p>
                </div>
              </v-card-text>
            </v-card>
          </div>

          <div v-if="dataCampaign[0].status === 1" class="row pa-3 mt-3 d-flex justify-center">
            <v-progress-circular
              v-if="isLoadingMore"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <v-btn v-else @click="loadMore()" outlined color="primary" rounded class="text-capitalize">Load More</v-btn>
          </div>

          <!-- MENU BAWAH -->
          <div v-if="dataCampaign[0].status === 1">
            <v-bottom-navigation
              v-if="!isLoading"
              color="white"
              fixed
              class="d-flex justify-space-center"
              horizontal
              style="bottom:-1px;box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important"
            >
              <div v-for="list in dataCampaign" :key="list.id">
                <v-btn text class="mr-0" color="primary" style="height:56px !important; color:#fff !important">
                  <span class="subtitle-2 font-weight-bold primary--text">{{ (list.level === 'A') ? 'Nano ' : 'Micro ' }} ({{ (list.level === 'A') ? selectQty : selectQty }}{{ '/'+list.qty }})</span>
                </v-btn>
              </div>
              <div class="pb-0 mb-0">
                <v-btn class="pt-5 pb-5 mt-2" @click.prevent="save()" rounded color="primary" style="color:#fff !important;">
                    <span class="caption font-weight-bold">Simpan</span>
                    <i class="fas fa-save mr-2"></i>
                </v-btn>
              </div>
            </v-bottom-navigation>
          </div>
          <div v-else >
            <v-card class="mt-5 round" color="white">
              <v-card-text class="d-flex justify-space-between">
                <p class="body-1 font-weight-bold black--text mb-0 mt-2">Total {{ dataCampaign[0].qty }} Influencer</p>
                <v-btn @click="goToReport()" rounded class="pt-5 pb-5 white--text text-capitalize"  color="primary">
                    <i class="far fa-file mr-2"></i>Lihat Report
                </v-btn>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>
    </div>

    <!-- DIALOG LOADING -->
    <v-dialog
      v-model="loadSelect"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
        class="round"
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG ALERT -->
    <v-dialog
      v-model="alert"
      max-width="420"
    >
      <v-alert type="error" class="round">
        {{alertMessage}} <button style="float:right" type="button" v-on:click="alert=false" class="close right" data-dismiss="alert-success" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </v-alert>
    </v-dialog>

    <!-- DIALOG SAVE -->
    <v-dialog
      v-model="saveDialog"
      max-width="400"
    >
      <v-card>
        <div class="d-flex justify-center pt-15">
          <v-progress-circular
            v-if="isLoadingSave"
            :width="5"
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <div v-else>
            <i v-if="cartSuccess" class="far fa-check-circle primary--text" style="font-size:55px"></i>
            <i v-else class="far fa-times-circle error--text" style="font-size:55px"></i>
          </div>
        </div>
        <v-card-title class="d-flex justify-center">
          <div v-if="!isLoadingSave">
            <p v-if="cartSuccess" class="text1-8 primary--text mb-0">Influencer telah disimpan</p>
            <p v-else class="text-18 error--text mb-0">Influencer gagal disimpan</p>
          </div>
        </v-card-title>
        <!-- <v-card-text>Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.</v-card-text> -->
        <v-card-actions v-if="!isLoadingSave" class="d-flex justify-center">
          <!-- <v-spacer></v-spacer> -->
            <v-btn
              color="primary"
              class="text-capitalize pl-5 pr-5"
              rounded
              @click="reloadPage()"
            >
              Lihat Detail
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DETAIL -->
    <v-row justify="center">
      <v-dialog
        v-model="isDetail"
        scrollable
        max-width="420"
      >
        <v-card
          height="500"
          class="round"
        >
          <v-card-title>
            <div class="row  pa-3 pr-0 pt-0 pb-0 d-flex justify-space-between">
              <p class="pt-3">Detail Influencer</p>
              <v-btn @click="isDetail = false" icon class="mt-1"><i class="fas fa-times"></i></v-btn>
            </div>
          </v-card-title>
          <v-card-text class="pt-6 pb-6">
            <div class="row d-flex justify-space-between pt-3">
              <v-col cols="12" class="text-center pt-0" 
              >
                  <v-avatar
                  color="primary"
                  size="110"
                  >
                    <v-img :src="dataInflu.photo"></v-img>
                  </v-avatar>
              </v-col>
              <v-col cols="12" class="pt-0 text-center" 
              >
                <p class="body-1 black--text font-weight-bold mb-1 text-capitalize">{{ dataInflu.name }} <v-icon class="primary--text text-14"> {{ icons.mdiLightningBolt }} </v-icon></p>
                <p class="text-12 mb-1"><i class="fas fa-map-marker-alt mr-2 text-capitalize"></i>{{ dataInflu.city_name }}</p>
                <v-chip 
                    class="caption ma-1"
                    color="secondary"
                    v-for="cate in dataInflu.categories" :key="cate.id"
                >{{ cate.name }}
                </v-chip>
              </v-col>
            </div>
            <v-row>
              <v-col  cols="12" class="pt-1 text-center">
                <!-- <v-btn @click="book(dataInflu.instagram)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" color="primary"><i class="fas fa-shopping-cart mr-2"></i>Book Now</v-btn> -->
              </v-col>
            </v-row>
            <v-row class="mb-2 ml-1 mr-1">
                <v-divider></v-divider>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-1">
                <v-card class="round third">
                  <v-card-text class="text-center black--text">
                    <p class="font-weight-bold text-14 black--text mb-2">Summary Instagram</p>
                    <v-chip 
                        class="caption"
                        color="primary"
                    >
                      <p class="mb-0 text-14 white--text"><i class="fab fa-instagram pr-1"></i>{{ dataInflu.instagram ? dataInflu.instagram : 'Tidak tersedia'}}</p>
                    </v-chip>
                    <div class="row mt-3 ml-0 mr-0 d-flex justify-space-between">
                      <v-col cols="6" class="text-center pa-0">
                        <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.follower_ig ? dataInflu.follower_ig : '-' | nFormatter }}</p>
                        <p class="text-12">Followers</p>
                      </v-col>
                      <v-col cols="6" class="text-center pa-0">
                        <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.engagement_rate ? dataInflu.engagement_rate+'%': '-' }}</p>
                        <p class="text-12">Engagement Rate</p>
                      </v-col>
                    </div>
                    <div class="row mt-3 ml-0 mr-0 d-flex justify-space-between">
                      <v-col cols="6" class="text-center pa-0">
                        <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.avg_like_ig ? dataInflu.avg_like_ig : '-' | nFormatter }}</p>
                        <p class="text-12">Avg. Like</p>
                      </v-col>
                      <v-col cols="6" class="text-center pa-0">
                        <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.avg_comment_ig ? dataInflu.avg_comment_ig : '-' | nFormatter }}</p>
                        <p class="text-12">Avg. Comment</p>
                      </v-col>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="pt-3">
              <v-col cols="12" class="pt-1">
                <v-card class="round fourth">
                  <v-card-text class="text-center black--text">
                    <p class="font-weight-bold text-14 black--text mb-2">Summary Tiktok</p>
                    <v-chip 
                        class="caption"
                        color="primary"
                    >
                      <p class="mb-0 text-14 white--text"><i class="fab fa-tiktok pr-1"></i>{{ dataInflu.tiktok ? dataInflu.tiktok : ' Tidak tersedia'}}</p>
                    </v-chip>
                    <div class="row mt-3 ml-0 mr-0 d-flex justify-space-between">
                      <v-col cols="6" class="text-center pa-0">
                        <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.follower_tiktok ? dataInflu.follower_tiktok : '-' | nFormatter }}</p>
                        <p class="text-12">Followers</p>
                      </v-col>
                      <v-col cols="6" class="text-center pa-0">
                        <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.engagement_rate_tiktok ? dataInflu.engagement_rate_tiktok+'%': '-' }}</p>
                        <p class="text-12">Engagement Rate</p>
                      </v-col>
                    </div>
                    <div class="row mt-3 ml-0 mr-0 d-flex justify-space-between">
                      <v-col cols="6" class="text-center pa-0">
                        <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.avg_like_tiktok ? dataInflu.avg_like_tiktok : '-' | nFormatter }}</p>
                        <p class="text-12">Avg. Like</p>
                      </v-col>
                      <v-col cols="6" class="text-center pa-0">
                        <p class="text-18 black--text font-weight-bold mb-0">{{ dataInflu.avg_view_tiktok ? dataInflu.avg_view_tiktok : '-' | nFormatter }}</p>
                        <p class="text-12">Avg. View</p>
                      </v-col>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>        
    </v-row>
    <!-- END DIALOG DETAIL -->
  </div>
</template>

<script>
import axios from 'axios'
import { mdiLightningBoltOutline, mdiLightningBolt } from '@mdi/js'

export default {
  props:['data'],
  setup() {
    return {
      isLoading:true,
      isLoadingMore:false,
      isLoadingSave:false,
      isLoadingDetail:false,
      isDetail:false,
      loadSelect:false,
      saveDialog:false,
      cartSuccess:false,
      alert:false,
      alertMessage:'',
      reload:true,
      isMobileWidth:0,
      idCampaign:'',
      dataCampaign:[],
      dataInflu:'',
      selectInflu:'',
      selectQty:0,
      offset:1,
      type:'',
      bpm:0,
      num:1,
      icons: {
        mdiLightningBoltOutline,
        mdiLightningBolt
      },
      listInfluencers:[]
    }
  },
  mounted(){
    let item = this.$route.query.item
    item = this.decryptData(item)
    this.idCampaign = item

    if(item > 0){
      if(this.isMobileWidth === 0){
        if (localStorage.getItem('reloaded')) {
            localStorage.removeItem('reloaded');
            this.reload = false
        } else {
            localStorage.setItem('reloaded', '1');
            location.reload();
        }
      }
      this.loadCampaign();
      window.scrollTo(0,0);
    } else {
      this.$router.push({name:'/'});
    }

    window.scrollTo(0,0);
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    encryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg='
      return this.$CryptoJS.AES.encrypt(val.toString(), secretKey).toString();
    },
    decryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg=';
      let decryptData = this.$CryptoJS.AES.decrypt(val, secretKey).toString(this.CryptoJS.enc.Utf8);
      return decryptData;
    },
    loadCampaign(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'quotation/'+this.idCampaign, {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response.status === 200){
            this.dataCampaign = response.data.data
            if(this.dataCampaign[0].status === 3){
              this.$router.push({name:'campaign'}); 
            }
            if(this.dataCampaign[0].platform === 'tiktok'){
              this.type = 'tiktok'
            } else {
              this.type = this.dataCampaign[0].level
            }
            this.bpm = this.dataCampaign[0].qty
            for(let a = 0; a < this.dataCampaign.length; a++){
              if(this.dataCampaign[a].level === 'A'){
                this.bpmA = parseInt(this.dataCampaign[a].qty);
              } else {
                this.bpmB = parseInt(this.dataCampaign[a].qty);
              }
            }
            if(this.dataCampaign[0].status === 1){
              this.loadInfluencers()
            } else {
              this.loadViewInfluencers()
            }
            // console.log(this.dataCampaign)
            // setTimeout(() => { 
            //   this.isLoading = false
            // }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    loadInfluencers(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      let endPoint
      if(this.type === 'A') {
        this.type = 'a'
      } else if (this.type === 'B'){
        this.type = 'b'
      } else if (this.type === 'C'){
        this.type = 'c'
      }
      if(this.type === 'tiktok'){
        endPoint = 'influencers/getTiktokHomeLogin/'+this.offset
      } else {
        endPoint = 'influencers/getInfluencersHomeLogin/'+this.type+'/'+this.offset
      }
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + endPoint, {
          headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response){
            let data = response.data;
            if(this.type === 'tiktok'){
              let filterMin
              let filterMax
              if(this.dataCampaign[0].level === 'A'){
                filterMax = 9999
                filterMin = 1000
              } else if(this.dataCampaign[0].level === 'B'){
                filterMax = 99999
                filterMin = 10000
              } else if(this.dataCampaign[0].level === 'C'){
                filterMax = 499999
                filterMin = 100000
              } else {
                filterMax = 9999999
                filterMin = 1000
              }
              data = data.filter(function(item) {
                return parseInt(item.follower_tiktok) >= filterMin && parseInt(item.follower_tiktok) <= filterMax
              })
            }
            for(let a=0; a < data.length; a++){
              data[a].num = this.num++
              data[a].choose = 0
              data[a].city_name === null ? data[a].city_name = data[a].city:''
              axios({
                url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                headers: {
                  'Authorization': 'Bearer '+ token
                },
                method: 'GET',
                responseType: 'blob',
              }).then((res) => {
                const blob = new Blob([res.data], { type: "application/JPG" });
                data[a].photo = URL.createObjectURL(blob);
              });
            }
            
            this.listInfluencers = data
            setTimeout(() => { 
              this.offset = 0
              this.isLoading = false
            }, 2000);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    loadViewInfluencers(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'quotation/getInfluencers/'+this.type+'/'+this.idCampaign, {
          headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response){
            let data = response.data;
            let num = 1
            for(let a=0; a < data.length; a++){
              data[a].num = num++
              data[a].city_name === null ? data[a].city_name = data[a].city:''
              axios({
                url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                headers: {
                  'Authorization': 'Bearer '+ token
                },
                method: 'GET',
                responseType: 'blob',
              }).then((res) => {
                const blob = new Blob([res.data], { type: "application/JPG" });
                data[a].photo = URL.createObjectURL(blob);
              });
            }
            this.listInfluencers = data
            setTimeout(() => { 
              this.isLoading = false
            }, 2000);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    loadMore(){
      this.isLoadingMore = true
      let token = localStorage.getItem('token')
      this.offset = this.offset + 13
      let endPoint = ''
      if(this.type === 'tiktok'){
        endPoint = 'influencers/getTiktokHomeLogin/'+this.offset
      } else {
        endPoint = 'influencers/getInfluencersHomeLogin/'+this.type+'/'+this.offset
      }
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + endPoint, {
          headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response){
            let data = response.data;
            if(this.type === 'tiktok'){
              let filterMin
              let filterMax
              if(this.dataCampaign[0].level === 'A'){
                filterMax = 9999
                filterMin = 1000
              } else if(this.dataCampaign[0].level === 'B'){
                filterMax = 99999
                filterMin = 10000
              } else if(this.dataCampaign[0].level === 'C'){
                filterMax = 499999
                filterMin = 100000
              } else {
                filterMax = 9999999
                filterMin = 1000
              }
              data = data.filter(function(item) {
                return parseInt(item.follower_tiktok) >= filterMin && parseInt(item.follower_tiktok) <= filterMax
              })
            }
            for(let a=0; a < data.length; a++){
              axios({
                url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                headers: {
                  'Authorization': 'Bearer '+ token
                },
                method: 'GET',
                responseType: 'blob',
              }).then((res) => {
                const blob = new Blob([res.data], { type: "application/JPG" });
                data[a].photo = URL.createObjectURL(blob);
              });
            }
            for(let a=0; a < data.length; a++){
              data[a].num = this.num++
              data[a].choose = 0
              this.listInfluencers.push(data[a])
            }
            setTimeout(() => { 
              this.isLoadingMore = false
              this.isLoading = false
            }, 2000);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    selected(num, id){
      this.loadSelect = true
      if(this.selectQty+1 > this.bpm){
        this.loadSelect = false
        this.alertMessage = 'Maksimal Pilih '+this.bpm+' Influencer'
        this.alert = true
      } else {
        let newNum = parseInt(num)-1
        this.selectInflu += id+','
        this.selectQty += 1
        this.listInfluencers[newNum].choose = 1
      }
      setTimeout(() => { 
        this.loadSelect = false
      }, 500);
    },
    unSelected(num, id){
      this.loadSelect = true
      let newNum = parseInt(num)-1
      let influcencers = this.selectInflu.split(",");
      let arrInflu = ''
      for(let a=0; a < influcencers.length; a++ ){
        if(influcencers[a] != ''){
          if(influcencers[a] != id){
            arrInflu += influcencers[a]+','
          }
        }
      }
      this.selectInflu = arrInflu
      this.selectQty -= 1
      this.listInfluencers[newNum].choose = 0
      setTimeout(() => { 
        this.loadSelect = false
      }, 500);
    },
    save(){
      if(this.selectQty > this.bpm){
        this.alertMessage = 'Maksimal pilih '+this.bpm+' influencer'
        this.alert = true
      } else if (this.selectQty === 0){
        this.alertMessage = 'Anda belum memilih influencer'
        this.alert = true
      } else if (this.selectQty < this.bpm){
        let minus = this.bpm - this.selectQty
        this.alertMessage = 'Silahkan pilih '+minus+' influencer lagi'
        this.alert = true
      } else if (this.selectQty === this.bpm){ //TRUE
        this.saveDialog = true
        this.isLoadingSave = true;
        let token = localStorage.getItem('token')
        axios.post(
          process.env.VUE_APP_API_ENDPOINT + 'quotation/saveInfluencer', {
            quotation_id:this.idCampaign,
            influencer_id: this.selectInflu,
          }, {
            headers: {
            'Authorization': 'Bearer '+ token
          }
        }).then(response => {
          if(response){
            // this.itemData = response.data
            // this.bpm = this.itemData.qty;
            setTimeout(() => { 
              this.isLoadingSave = false
              this.cartSuccess = true
            }, 200);
          }
        }).catch(error => {
          console.log(error)
          setTimeout(() => { 
            this.isLoadingSave = false
            this.cartSuccess = false
          }, 200);
        });
      } else {
        this.alertMessage = 'Error ! Terjadi kesalahan'
        this.alert = true
      }
    },
    goToDetail(id){
      this.isDetail = true;
      this.isLoadingDetail = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'influencers/getOneInfluencer/'+id, {
          headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response){
            let data = response.data;
            let num = 1
            for(let a=0; a < data.length; a++){
              data[a].num = num++
              data[a].city_name === null ? data[a].city_name = data[a].city:''
              axios({
                url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                headers: {
                  'Authorization': 'Bearer '+ token
                },
                method: 'GET',
                responseType: 'blob',
              }).then((res) => {
                const blob = new Blob([res.data], { type: "application/JPG" });
                data[a].photo = URL.createObjectURL(blob);
              });
            }
            this.dataInflu = data[0]
            setTimeout(() => { 
              this.isLoadingDetail = false
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    reloadPage(){
      location.reload();
    },
    goToReport(){
      this.$router.push({name:'campaign-report', query: {item: this.encryptData(this.idCampaign)}}); 
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    nFormatter(num) {
      if (num >= 1000000000) {
          return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
      }
      if (num >= 1000000) {
          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
          return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      return num;
    }
  }
}
</script>
